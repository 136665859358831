import * as React from "react";
// @ts-ignore
import {Col, Container, Form, Row, Button} from "react-bootstrap";

import * as style from './Content.module.scss';
import * as mainStyle from '../../index.module.scss';

import * as navStyle from '../../../../components/Navigation/Navigation.module.scss';
import * as contentStyle from '../../../../components/Content/Content.module.scss';
import {formButton, formInput, formLabel} from "../../../login/index.module.scss";
import {faArrowRight} from "@fortawesome/pro-light-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

type ContentProps = {
    title: string,
    description : string,
    caption : string,
    link : string,
    mediaPosLeft: string,
    image: string,
}

function Content(props: ContentProps) {
    return <>
        <Row className={mainStyle.box}>
            <Col lg={4} className={props.mediaPosLeft ? `order-last ` + style.paddingLeft : style.paddingRight + ' order-lg-first order-last '}>
                <div className={style.contentElement}>
                    <div className={style.caption}>{props.caption}</div>
                    <h2 className={style.headline}>{props.title}</h2>
                    <div className={style.description} dangerouslySetInnerHTML={{ __html: props.description }}/>
                    <a href={props.link} className={style.link}>Klicke hier um mehr zu erfahren!</a>
                </div>
            </Col>

            <Col lg={8} className={props.mediaPosLeft ? (style.paddingRight + ' text-start') : (style.paddingLeft + ' text-end')}>
                {props.image ? <div className={style.imageWrapper + (props.mediaPosLeft ? (' text-start') : (' text-end'))}> <img src={props.image} className={'img-fluid'} alt={'no img'} /> {props.badge ? <img src={props.badge} className={style.badgeImage} /> : null}</div> : null}
            </Col>
        </Row>
    </>;
}

export default Content;
